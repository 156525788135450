// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

//  GET Order LIST
export const OrderListData = createAsyncThunk('OrderTracking/OrderListData', async (params) => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/orderTakingListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/orderTakingList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        params,
        OrderList: response1.data.body.OrderTakinglist
      }
    } else {
    }
  }
})

//  GET Order LIST
export const OrderToWhatsappListData = createAsyncThunk('OrderTracking/OrderToWhatsappListData', async (params) => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/orderToWhatsappListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/orderToWhatsappList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        params,
        OrderToWhatsappList: response1.data.body.OrderTakinglist ? response1.data.body.OrderTakinglist : []
      }
    } else {
    }
  }
})


// Company search
export const onchangeCompanyData = createAsyncThunk('OrderTracking/onchangeCompanyData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/ChangeAutoCompanyNamejwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/ChangeAutoCompanyName`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListCompany: response1.data.body.CompanyList
      }
    } else {
    }
  }
}
)

// Item Code search
export const onchangeItemData = createAsyncThunk('OrderTracking/onchangeItemData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/ChangeAutoItemCodejwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/ChangeAutoItemCode`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListItem: response1.data.body.ItemList
      }
    } else {
    }
  }
}
)
export const getCurrentOrderStock = createAsyncThunk('OrderTracking/getCurrentOrderStock', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/getCurrentOrderStockJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/getCurrentOrderStock`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        Order_Qty: response1.data.body.Order_Qty,
        Current_Stock: response1.data.body.Current_Stock
      }
    } else {
      return {
        Order_Qty: 0,
        Current_Stock: 0
      }
    }
  }
}
)


// Item Name search
export const onchangeItemNameData = createAsyncThunk('OrderTracking/onchangeItemNameData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/ChangeAutoItemNamejwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/ChangeAutoItemName`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ScanSizeList: response1.data.body.ScanSizeList
      }
    } else {
    }
  }
}
)

//saveData Api
export const SaveData = createAsyncThunk('OrderTracking/SaveData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/saveOrderTakingjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/saveOrderTaking`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 3,
          message: response1.data.body.message
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 3,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        statusFlag: response1.data.body ? response1.data.body.statusFlag : 3,
        message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      statusFlag: 3,
      message: 'Server not reachable.Please try again later'
    }
  }

})

// Design Code search
export const onchangeDesignData = createAsyncThunk('OrderTracking/onchangeDesignData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/ChangeAutoDesignNamejwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/ChangeAutoDesignName`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListDesign: response1.data.body.DesignList
      }
    } else {
    }
  }
}
)

//Customer search
export const onchangeCustomerData = createAsyncThunk('OrderTracking/onchangeCustomerData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/customerListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/customerList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        CustomerList: response1.data.body.CustomerList
      }
    } else {
    }
  }
}
)

//Customer search
export const CheckCustomerOrders = createAsyncThunk('OrderTracking/CheckCustomerOrders', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/ShowCustomerOrdersjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/ShowCustomerOrders`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        CustomerOrdersList: response1.data.body.CustomerList
      }
    } else {
    }
  }
}
)

//Edit Order List
export const EditData = createAsyncThunk('OrderTracking/EditData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/editOrderTakingjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/editOrderTaking`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        EditItemOrderList: response1.data.body.EditOrderList
      }
    } else {
    }
  }
}
)

// Delete Data Api
export const DeleteData = createAsyncThunk('OrderTracking/DeleteData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/deleteOrderTakingjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/deleteOrderTaking`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag: response1.data.body.statusFlag,
        message: response1.data.body.message
      }
    }
  } else {
    return {
      statusFlag: response1.data.body.statusFlag,
      message: response1.data.body.message
    }
  }
})

// Hold Data Api
export const HoldOrderData = createAsyncThunk('OrderTracking/HoldOrderData', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/holdOrderTakingJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    //  params = {jwtToken:response.data.body.token}
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/holdOrderTaking`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        hold_statusFlag: response1.data.body.statusFlag,
        hold_message: response1.data.body.message
      }
    }
  } else {
    return {
      hold_statusFlag: response1.data.body.statusFlag,
      hold_message: response1.data.body.message
    }
  }
})

//Print Order Slip
export const printOrderSlip = createAsyncThunk('OrderTracking/printOrderSlip', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/printOrderSlipjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/printOrderSlip`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        OrderSlipList: response1.data.body.OrderSlip,
        CompanyList: response1.data.body.CompanyArray,
        Order_CustomerList: response1.data.body.CustomerArray,
        ItemCount: response1.data.body.ItemCount,
        ItemLists: response1.data.body.ItemLists,
        BrandLists: response1.data.body.BrandLists,
        BrandwiseItemLists: response1.data.body.BrandwiseItemLists,
        brandWiseTotalItemCategortList: response1.data.body.brandWiseTotalItemCategortList
      }
    } else {
    }
  }
}
)

//onChange Qty
export const onChangeQty = createAsyncThunk('OrderTracking/onChangeQty', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/onChangeQtyjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/onChangeQty`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        StockCount: response1.data.body.StockCount
      }
    } else {
    }
  }
}
)

//Send Order To Whatsapp
export const sendOrderToWhatsapp = createAsyncThunk('OrderTracking/sendOrderToWhatsapp', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/sendOrderToWhatsappJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/sendOrderToWhatsapp`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        SentOrderToWAMessage: response1.data.body.message,
        SentOrderToWAStatusFlag: response1.data.body.statusFlag
      }
    } else {
    }
  }
}
)

//Customer search
export const UpdateBlockCustomerData = createAsyncThunk('OrderTracking/UpdateBlockCustomerData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/updateBlockCustomerJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/updateBlockCustomer`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          Update_Message: response1.data.body.message,
          Update_StatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2
        }
      } else {
        return {
          Update_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
          Update_StatusFlag: response1.data.body ? response1.data.body.GR_Delete_statusFlag : 2
        }
      }
    } else {
      return {
        Update_Message: 'Server not reachable.Please try again later',
        Update_StatusFlag: 2
      }
    }
  } catch (error) {
    return {
      Update_Message: 'Server not reachable.Please try again later',
      Update_StatusFlag: 2
    }
  }
}
)

/******************* Close Pending Order *******************/

export const getClosePendingOrderList = createAsyncThunk('OrderTracking/closePendingOrderListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/closePendingOrderListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/closePendingOrderList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          ClosePendingOrderList: response1.data.body && response1.data.body.ClosePendingOrderList ? response1.data.body.ClosePendingOrderList : []
        }
      } else {
        return {
          ClosePendingOrderList: response1.data.body ? response1.data.body.ClosePendingOrderList : []
        }
      }
    } else {
      return {
        ClosePendingOrderList: response.data.body ? response.data.body.ClosePendingOrderList : []
      }
    }
  } catch (error) {
    return {
      ClosePendingOrderList: []
    }
  }
})

//Customer search
export const SaveClosePendingOrderData = createAsyncThunk('OrderTracking/SaveClosePendingOrderData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/orderManagement/orderTaking/SaveClosePendingOrderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/orderManagement/orderTaking/SaveClosePendingOrder`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          closePendingMessage: response1.data.body.message,
          closePendingStatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2
        }
      } else {
        return {
          closePendingMessage: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
          closePendingStatusFlag: response1.data.body ? response1.data.body.GR_Delete_statusFlag : 2
        }
      }
    } else {
      return {
        closePendingMessage: 'Server not reachable.Please try again later',
        closePendingStatusFlag: 2
      }
    }
  } catch (error) {
    return {
      closePendingMessage: 'Server not reachable.Please try again later',
      closePendingStatusFlag: 2
    }
  }
}
)

export const getBrandList = createAsyncThunk('ItemgroupMaster/brandFilterListJwt', async params => {
  try {
    const response = await axios.post(`${api.api_url}/common/brandFilterListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/common/brandFilterList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          brand_List: response1.data.body && response1.data.body.BrandArray ? response1.data.body.BrandArray : [],
          brand_List_Message: response1.data.body.message
        }
      } else {
        return {
          brand_List: response1.data.body ? response1.data.body.BrandArray : [],
          brand_List_Message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        brand_List: response.data.body ? response.data.body.BrandArray : [],
        brand_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      brand_List: [],
      brand_List_Message: 'Server not reachable.Please try again later'
    }
  }
})

export const OrderTrackingSlice = createSlice({
  name: 'OrderTracking',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    RateCartlist: [],
    statuslist: [],
    statusFlag: 0,
    statuscode: 0,
    ListDesign: [],
    CompanyList: [],
    CustomerList: [],
    CustomerOrdersList: [],
    ListCompany: [],
    ListItem: [],
    OrderSlipList: [],
    ItemCount: [],
    StockCount: -1,
    OrderList: [],
    EditItemOrderList: [],
    Order_CustomerList: [],
    ScanSizeList: [],
    ItemLists: [],
    BrandLists: [],
    BrandwiseItemLists: [],
    brandWiseTotalItemCategortList: [],
    OrderToWhatsappList: [],
    SentOrderToWAStatusFlag: 0,
    SentOrderToWAMessage: '',
    Order_Qty: 0,
    Current_Stock: 0,
    hold_statusFlag: 0,
    hold_message: '',
    Update_Message: '',
    Update_StatusFlag: 0,
    /******************* Close Pending Order *******************/
    ClosePendingOrderList: [],
    closePendingStatusFlag: 0,
    closePendingMessage: '',
    brand_List: [],
    brand_List_Message: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.SentOrderToWAStatusFlag = action.payload.SentOrderToWAStatusFlag
      state.SentOrderToWAMessage = action.payload.SentOrderToWAMessage
      state.hold_statusFlag = action.payload.hold_statusFlag
      state.hold_message = action.payload.hold_message
      state.Update_Message = action.payload.Update_Message
      state.Update_StatusFlag = action.payload.Update_StatusFlag
      state.closePendingStatusFlag = action.payload.closePendingStatusFlag
      state.closePendingMessage = action.payload.closePendingMessage
    },
    handleFlag: (state, action) => {
      state.StockCount = action.payload.StockCount
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    },
    handlenavigate: (state, action) => {
      state.navigateaction = action.payload
    },
    clearstoreData: (state, action) => {
      state.EditItemOrderList = action.payload.EditItemOrderList
      state.CustomerList = action.payload.CustomerList
      state.CustomerOrdersList = action.payload.CustomerOrdersList
      state.ListCompany = action.payload.ListCompany
      state.OrderSlipList = action.payload.OrderSlipList
      state.ListDesign = action.payload.ListDesign
      state.ItemLists = action.payload.ItemLists
      state.BrandLists = action.payload.BrandLists
      state.BrandwiseItemLists = action.payload.BrandwiseItemLists
      state.OrderToWhatsappList = action.payload.OrderToWhatsappList
      state.ClosePendingOrderList = action.payload.ClosePendingOrderList
      state.brandWiseTotalItemCategortList = action.payload.brandWiseTotalItemCategortList
    }
  },
  extraReducers: builder => {
    builder.addCase(SaveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
      .addCase(DeleteData.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })
      .addCase(onChangeQty.fulfilled, (state, action) => {
        state.StockCount = action.payload.StockCount
      })
      .addCase(onchangeCompanyData.fulfilled, (state, action) => {
        state.ListCompany = action.payload.ListCompany
      })
      .addCase(onchangeDesignData.fulfilled, (state, action) => {
        state.ListDesign = action.payload.ListDesign
      })
      .addCase(EditData.fulfilled, (state, action) => {
        state.EditItemOrderList = action.payload.EditItemOrderList
      })
      .addCase(printOrderSlip.fulfilled, (state, action) => {
        state.OrderSlipList = action.payload.OrderSlipList
        state.CompanyList = action.payload.CompanyList
        state.ItemCount = action.payload.ItemCount
        state.Order_CustomerList = action.payload.Order_CustomerList
        state.ItemLists = action.payload.ItemLists
        state.BrandLists = action.payload.BrandLists
        state.BrandwiseItemLists = action.payload.BrandwiseItemLists
        state.brandWiseTotalItemCategortList = action.payload.brandWiseTotalItemCategortList
      })
      .addCase(onchangeCustomerData.fulfilled, (state, action) => {
        state.CustomerList = action.payload.CustomerList
      })
      .addCase(CheckCustomerOrders.fulfilled, (state, action) => {
        state.CustomerOrdersList = action.payload.CustomerOrdersList
      })
      .addCase(onchangeItemData.fulfilled, (state, action) => {
        state.ListItem = action.payload.ListItem
      })
      .addCase(onchangeItemNameData.fulfilled, (state, action) => {
        state.ScanSizeList = action.payload.ScanSizeList
      })
      .addCase(OrderListData.fulfilled, (state, action) => {
        state.OrderList = action.payload.OrderList
        state.params = action.payload.params
      })
      .addCase(OrderToWhatsappListData.fulfilled, (state, action) => {
        state.OrderToWhatsappList = action.payload.OrderToWhatsappList
        state.params = action.payload.params
      })
      .addCase(sendOrderToWhatsapp.fulfilled, (state, action) => {
        state.SentOrderToWAStatusFlag = action.payload.SentOrderToWAStatusFlag
        state.SentOrderToWAMessage = action.payload.SentOrderToWAMessage
      })
      .addCase(getCurrentOrderStock.fulfilled, (state, action) => {
        state.Order_Qty = action.payload.Order_Qty
        state.Current_Stock = action.payload.Current_Stock
      }).addCase(HoldOrderData.fulfilled, (state, action) => {
        state.hold_statusFlag = action.payload.hold_statusFlag
        state.hold_message = action.payload.hold_message
      }).addCase(UpdateBlockCustomerData.fulfilled, (state, action) => {
        state.Update_Message = action.payload.Update_Message
        state.Update_StatusFlag = action.payload.Update_StatusFlag
      }).addCase(getClosePendingOrderList.fulfilled, (state, action) => {
        state.ClosePendingOrderList = action.payload.ClosePendingOrderList
      }).addCase(SaveClosePendingOrderData.fulfilled, (state, action) => {
        state.closePendingStatusFlag = action.payload.closePendingStatusFlag
        state.closePendingMessage = action.payload.closePendingMessage
      }).addCase(getBrandList.fulfilled, (state, action) => {
        state.brand_List = action.payload.brand_List
        state.brand_List_Message = action.payload.brand_List_Message
      })


  }
})
export const {
  handleStatusFlag, handleedit, clearstoreData, handlenavigate, handleFlag
} = OrderTrackingSlice.actions
export default OrderTrackingSlice.reducer
