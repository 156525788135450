export const api = {
  //Local Url
        // api_url: 'http://localhost:8082/api'
   //Test Url
      // api_url: 'http://172.16.1.201:8083/api'
   //Live Url
      // api_url: 'http://192.168.1.8:8034/api'
      //Cloud Url
      api_url:  'https://prettygirlapi.trio-s.com/api'
}
export const Common_variable = {
  ADDITEM:'Please add atleast one employee',
  Save: 'Save',
  Update: 'Update',
  Save_continue: 'Save & Continue',
  Close: 'Close',
  Yes: 'Yes',
  No: 'No',
  Active: 'Active',
  Inactive: 'Inactive',
  Edit: 'Edit',
  Delete: 'Delete',
  Confirmation: 'Confirmation',
  Add: 'Add',
  Show: 'Show',
  Status: "Status",
  TransType: 2,
  // India_Rupee_Formatter : new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 3 }),
  Regax_validation: /^\p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$/,
  characters_pattern: /^[a-zA-Z0-9]*$/,
  numbercharacters_pattern: /^[a-zA-Z0-9-@._ ]*$/,
  numberpattern: /^[0-9]*$/,
  ShortCodepattern: /^[0-9a-zA-Z-._()' ]*$/,
  numberdotpattern: /^[0-9.-]*$/,
  specialcharacters_pattern: /^[a-zA-Z-._ ]*$/,
  onlycharacters: /^[a-zA-Z0-9-_]*$/,
  itemname_pattern: /^[a-zA-Z0-9-._ ]*$/,
  gstinPattern : /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  numberOnlyPattern : /^[0-9\b]+$/,
  onlynumberdotpattern: /^[0-9.]*$/,
  refNopattern: /^[0-9a-zA-Z-._'"]*$/,
  alphanumeric_ifan_pattern: /^[a-zA-Z0-9-_ ]*$/,
  STATUSCUSTOMERLIST: [
    { label: "All", value: 0 },
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
    { label: "Block", value: 3 }
  ],
  STATUSLIST: [
    { label: "All", value: 0 },
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 }    
  ],
  STATUSJOBLIST: [
    { label: "All", value: 0 },
    { label: "Completed", value: 3 },
    { label: "Pending", value: 4 },
    { label: "Transfered", value: 5 },
    { label: "Rate not yet fixed", value: 7 }
  ],
  STATUSJOBCUTTINGLIST: [
    { label: "All", value: 0 },
    { label: "Completed", value: 3 },
    { label: "Pending", value: 4 }
  ],
  STATUSEBROIDERLIST: [
    { label: "All", value: 0 },
    { label: "Completed", value: 3 },
    { label: "Pending", value: 4 }
  ],
  STATUSRATELIST: [
    { label: "All", value: 0 },
    { label: "Not Yet Fixed", value: 1 }
  ],
  STATUS_PROCESSLIST: [
    { label: "Pending", value: 4 },
    { label: "Completed", value: 3 }
  ],
  CHECKLIST: [
    { label: "All Employees", value: 0 },
    { label: "Specific Employees", value: 1 }
  ],
  ORDERLIST: [
    { label: "All", value: 0 },
    { label: "Active", value: 1 },
    { label: "Cancelled", value: 2 },
    { label: "Hold", value: 3 }
  ],
  PURCHASEORDERLIST: [
    { label: "All", value: 0 },
    { label: "Active", value: 1 },
    { label: "Cancelled", value: 2 }
  ],
  SROCKADJUSTMENTSTATUSLIST: [
    { label: "All", value: -1 },
    { label: "Pending", value: 0 },
    { label: "Verified", value: 1 }
  ],
  India_Rupee_Formatter : (value) => new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value),
  PDFIndia_Rupee_Formatter : (value) => new Intl.NumberFormat('en-IN', {
    currency: 'INR'
  }).format(value)
}


//Business partner
export const BUSINESS = {
  PERTNER_NAME: "Please enter business partner name",
  PERTNER_NAME_VALID: "Please enter valid business partner name",
  CATEGORY_NAME: "Please select business partnercategory",
  MOBILE_ERROR: "Please enter mobile number",
  MOBILE_VALIDATION: /^((\\+91-?)|0)?[0-9]{10}$/,
  MOBILE_VALIDATIONERROR: 'Please enter valid mobile number',
  BusinessPartnerName_VALIDATION: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
  BusinessPartnerName_VALIDATIONERROR: 'Please enter valid business partner name',
  BUSINESS_CATEGORY: "Business Partner Category",
  PARTNER_NAME: "Business Partner Name",
  PARTNER_TAMIL: " Business Partner Tamil Name",
  ADDRESS_1: "Address Line - 1",
  ADDRESS_2: "Address Line - 2",
  CITY: "City",
  PINCODE: "Pincode",
  CONTACT_PERSON: "Contact Person",
  EMAIL: "Email",
  MOBILE: "Mobile",
  WHATSAPP: "Whatsapp",
  TELEPHONE: "Telephone",
  PARTNER_DELETE: " Are you sure you want to delete this partner ?"
}

// USER MESSAGE
export const USER = {
  EMPLOYEENAME_ERROR: 'Please enter employee name',
  USERNAME_ERROR: 'Please enter user name',
  USERID_ERROR: 'Please enter user ID',
  DEVICESID_ERROR: 'Please enter devices ID',
  PASSWORD_ERROR: 'Please enter password',
  PIN_ERROR: 'Please enter Pin',
  PIN_LIMIT: "Pin should be minimum 4 characters",
  PASSWORD_LIMIT: "Password should be minimum 6 characters",
  USERROLE_ERROR: 'Please select user role',
  USERMENU_ERROR: 'Please select menu privileges',
  BRANCHNAME_ERROR: 'Please select branch name',
  NEWPASSWORD_ERROR: 'Please enter new password',
  CONFORMPASSWORD_ERROR: 'Please enter confirm password',
  EQUALCONFORMPASSWORD_ERROR: 'confirm password dose not match',
  PASSWORD_VALIDATION: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
  PASSWORD_VALIDATIONERROR: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
  DEVICEID_ERROR: 'Please enter device ID'
}

// MASTER
export const ITEMGROUP = {
  BRANDNAME_ERROR: "Please select brand",
  ITEMGROUPNAME_ERROR: "Please enter item group name",
  SHORTNAME_ERROR: "Please enter short name",
  ITEMGROUPNAME_VALIDATIONERROR: "Please enter valid item group name",
  PRICE_ERROR: "Please enter price"
}

export const BRAND = {
  BRANDNAME_ERROR: "Please enter brand name",
  BRANDNAME_VALIDATIONERROR: "Please enter valid brand name"
}

export const IRONMACHINE = {
  IRONMACHINENO_ERROR: "Please enter iron machine no",
  IRONMACHINENAME_ERROR: "Please enter short name",
  IRONMACHINENO_VALIDATIONERROR: "Please enter valid iron machine no"
}

export const MATERIAL = {
  MATERIALNAME_ERROR: "Please enter material name"
}

export const ITEMSUBGROUP = {
  ITEMSUBGROUPNAME_ERROR: "Please enter item subcategory name",
  ITEMSUBGROUPNAME_VALIDATIONERROR: "Please enter valid item group name"
}

// Color
export const COLOR = {
  COLORNAME_ERROR: "Please enter color name"
}

// Customer
export const CUSTOMER = {
  CUSTOMERNAME_ERROR: "Please enter customer name",
  STATE_ERROR: "Please select state",
  EMAIL_ERROR: "Please enter valid email",
  PINCODE_ERROR: 'Please enter pincode',
  PINCODE_VALID: 'Please enter valid pincode',
  MOBILE_ERROR: "Please enter mobile number",
  GSTIN_NUMBER:'Please enter valid Gstin No.',
  MOBILE_FORAMAT:"Please enter valid Mobile No.",
  CITY_ERROR:'Please enter city'
}

// Supplier
export const SUPPLIER = {
  SUPPLIERNAME_ERROR: "Please enter supplier name",
  STATE_ERROR: "Please select state",
  EMAIL_ERROR: "Please enter valid email",
  PINCODE_ERROR: 'Please enter pincode',
  PINCODE_VALID: 'Please enter valid pincode',
  MOBILE_ERROR: "Please enter mobile number",
  GSTIN_NUMBER:'Please enter valid Gstin No.',
  MOBILE_FORAMAT:"Please enter valid Mobile No.",
  CITY_ERROR:'Please enter city'
}

// Agent
export const AGENT = {
  AGENTNAME_ERROR: "Please enter agent name",
  STATE_ERROR: "Please select state",
  EMAIL_ERROR: "Please enter valid email",
  PINCODE_ERROR: 'Please enter pincode',
  PINCODE_VALID: 'Please enter valid pincode',
  MOBILE_ERROR: "Please enter mobile number",
  MOBILE_FORAMAT:"Please enter valid Mobile No.",
  CITY_ERROR:'Please enter city'
}

//
export const EMBROIDER = {
  EMBROIDERNAME_ERROR: "Please enter embroider name",
  STATE_ERROR: "Please select state",
  EMAIL_ERROR: "Please enter valid email",
  PINCODE_ERROR: 'Please enter pincode',
  PINCODE_VALID: 'Please enter valid pincode',
  MOBILE_ERROR: "Please enter mobile number",
  MOBILE_FORAMAT:"Please enter valid Mobile No.",
  CITY_ERROR:'Please enter city'
}

// Rate Card
export const RATECARD = {
  DESIGNNAME_ERROR: "Please select design",
  RATE_ERROR: "Please enter rate"
}

// Designation
export const DESIGNATION = {
  DESIGNATIONNAME_ERROR: "Please enter designation name"
}

// DEPARTMENT
export const DEPARTMENT = {
  DEPARTMENTNAME_ERROR: "Please enter department name"
}

// MACHINE
export const MACHINE = {
  MACHINENAME_ERROR: "Please enter machine id"
}

// DESIGN
export const DESIGN = {
  DESIGNNAME_ERROR: "Please enter design id"
}

// EMPLOYEE
export const EMPLOYEE = {
  EMPLOYEENAME_ERROR: "Please enter employee name",
  EMPLOYEECODE_ERROR: "Please enter employee ID",
  GENDER_ERROR: "Please select gender name",
  MARITAL_ERROR: "Please select marital status",
  SPOUSENAME_ERROR: "Please enter spouse name",
  BLOODGROUP_ERROR: "Please select blood group",
  DATEBIRTH_ERROR: "Please select date of birth",
  ADDRESS_ERROR: "Please enter address",
  CITY_ERROR: "Please enter city",
  STATE_ERROR: "Please select state",
  EMAIL_ERROR: "Please enter valid email",
  PINCODE_ERROR: 'Please enter pincode',
  PINCODE_VALID: 'Please enter valid pincode',
  MOBILE_FORAMAT: "Please enter valid Mobile No.",
  MOBILE_ERROR: 'Please enter Mobile No.',
  CONTACT_FORAMAT: "Please enter valid Mobile No.",
  CONTACT_ERROR: 'Please enter Mobile No.',
  PAN_ERROR: 'Please enter pan No.',
  PAN_FORAMAT: "Please enter valid pan No.",
  AADHNAR_ERROR: 'Please enter aadhar No.',
  IFSC_ERROR: 'Please enter IFSC No.',
  BANKNAME_ERRROR: 'Please enter bank name',
  BRANCHNAME_ERROR: 'Please enter branch name',
  ACCCOUNTNO_ERROR: 'Please enter account No.',
  NAMEPERBANK_ERROR:'Name as per bank account',
  EMPLOYEECATEGEORY_ERROR: "Please select employee Category",
  DEPARTMENT_ERROR: "Please select department",
  DESIGNATION_ERROR: "Please select designation",
  EMPLOYEE_TYPE_ERROR: "Please select employee type"
}

// ITEM MASTER
export const ITEM = {
  ITEMSTARINGSIZENAME_ERROR: "Please enter starting size",
  ITEMENDINGSIZENAME_ERROR: "Please enter ending size",
  ITEMEVALIDNAME_ERROR: "Please enter valid size",
  ITEMEVALIDGREADERSIZENAME_ERROR: "Ending size should be greater than staring size",
  ITEMGROUPNAME_ERROR: "Please select item group name",
  ITEMNAME_ERROR: "Please enter item category",
  ITEMS_ERROR: "Please add atleast one item",
  ITEMGROUPNAME_ERROR: "Please select item group"
}

// Company MASTER
export const COMPANY = {
  characters_pattern: /^[a-zA-Z0-9]*$/,
  numbercharacters_pattern: /^[a-zA-Z0-9-@._ ]*$/,
  itemname_pattern: /^[a-zA-Z0-9-._ ]*$/,
  numberpattern: /^[0-9]*$/,
  numberdotpattern: /^[0-9.]*$/,
  specialcharacters_pattern: /^[a-zA-Z-@._ ]*$/,
  onlycharacters: /^[a-zA-Z0-9-_]*$/,
  COMPANYNAME_ERROR: "Please enter company name",
  ADDRESS_ERROR: "Please enter address",
  CITY_ERROR: "Please enter city",
  STATE_ERROR: "Please select state",
  EMAIL_ERROR: "Please enter valid email",
  PINCODE_ERROR: 'Please enter pincode',
  PINCODE_VALID: 'Please enter valid pincode',
  MOBILE_format: "Please enter valid Mobile No.",
  MOBILE_ERROR: 'Please enter Mobile No.',
  GSTIN_ERROR: 'Please enter valid GSTIN'

}

//Job Card
export const JOBCARD = {
  SET_ERROR: "Please enter no. of set",
  SIZE_ERROR: "Please select size",
  COLOR_ERROR: "Please select color",
  JOBTYPE_ERROR: "Please select job type",
  BRANDNAME_ERROR: "Please select brand",
  ITEMNAME_ERROR: "Please enter item category",
  MACHINENAME_ERROR: "Please enter machine id",
  DESIGNNAME_ERROR: "Please enter design id",
  EMPLOYEENAME_ERROR: "Please enter employee name",
  ITEMSUBCATEGORYNAME_ERROR: "Please select item subcategory"
}

// JobCutting

export const JOBCUTTING = {
  SET_ERROR: "Please enter no. of set",
  SIZE_ERROR: "Please select size",
  COLOR_ERROR: "Please select color",
  JOBTYPE_ERROR: "Please select job type",
  BRANDNAME_ERROR: "Please select brand",
  ITEMNAME_ERROR: "Please enter item category",
  MACHINENAME_ERROR: "Please enter machine id",
  DESIGNNAME_ERROR: "Please enter design id",
  EMPLOYEENAME_ERROR: "Please enter employee name",
  ITEMSUBCATEGORYNAME_ERROR: "Please select item subcategory"
}

// Embroide Entry
export const JOBEMBROIDE = {
  SET_ERROR: "Please enter no. of set",
  SIZE_ERROR: "Please select size",
  COLOR_ERROR: "Please select color",
  JOBTYPE_ERROR: "Please select job type",
  BRANDNAME_ERROR: "Please select brand",
  ITEMNAME_ERROR: "Please enter item category",
  MACHINENAME_ERROR: "Please enter machine id",
  DESIGNNAME_ERROR: "Please enter design id",
  EMPLOYEENAME_ERROR: "Please enter embroider name",
  RATE_ERROR: "Please enter rate",
  ITEMS_ERROR: "Please add atleast one item",
  SET_VALID_ERROR: "Please enter valid no. of set"
}

//Item Management 
export const ITEMMANAGEMENT = {
  SIZE_ERROR: "Please select size",
  COLOR_ERROR: "Please select color",
  BRANDNAME_ERROR: "Please select brand",
  ITEMNAME_ERROR: "Please select item category",
  DESIGNNAME_ERROR: "Please enter design id",
  ITEMSTARINGSIZENAME_ERROR: "Please enter starting size",
  ITEMENDINGSIZENAME_ERROR: "Please enter ending size",
  ITEMEVALIDNAME_ERROR: "Please enter valid size",
  ITEMEVALIDGREADERSIZENAME_ERROR: "Ending size should be greater than staring size",
  ITEMGROUPNAME_ERROR: "Please select item group name",
  ITEMS_ERROR: "Please add atleast one item",
  TYPE_ERROR: "Please select type",
  ITEMCATEGORYNAME_ERROR: "Please select item category name",
  ITEMS_IMAGE_UPLOAD_ERROR: "Please upload image"
}
// Order Taking
export const ORDERTAKING = {
  COMPANYNAME_ERROR: "Please select customer",
  ITEMS_ERROR: "Please add atleast one item",
  ITEMSNAME_ERROR: "Please Select item",
  QTY_ERROR: "Please enter qty",
  ARRAY_QTY_ERROR: "Please enter qty for  "  
}

// Purchase Order
export const PURCHASEORDER = {
  SUPPLIERNAME_ERROR: "Please select supplier",
  ITEMS_ERROR: "Please add atleast one item",
  ITEMSNAME_ERROR: "Please Select item",
  QTY_ERROR: "Please enter qty",
  ARRAY_QTY_ERROR: "Please enter color for  ",
  COLOR_ERROR: "Please select color",
  MATERIAL_ERROR: "Please select material",
  DESIGN_ERROR: "Please select design id/PO No.",
  UNIT_ERROR: "Please select unit",
  REFNO_ERROR: "Please enter ref no.",
  VALIDQTY_ERROR: "Please enter valid qty",
  VALIDDESIGN_ERROR: "Please select valid design id/PO No.",
  VALIDMATERIAL_ERROR: "Please select valid material"
}

// Purchase Order
export const MATERIALINWARD = {
  SUPPLIERNAME_ERROR: "Please select supplier",
  ITEMS_ERROR: "Please add atleast one item",
  ITEMSNAME_ERROR: "Please Select item",
  QTY_ERROR: "Please enter valid qty",
  ARRAY_QTY_ERROR: "Please enter color for  ",
  COLOR_ERROR: "Please select color",
  PO_ERROR: "Please select PO No.",
  DESIGN_ERROR: "Please select design ID",
  NOOFBOX_ERROR: "Please enter no. of bale",
  LRNO_ERROR: "Please enter LR No.",
  onlycharacters: /^[a-zA-Z0-9-_]*$/,
  LR_ERROR: "Please enter LR No."
}

//Settings Screen 
export const SETTINGS = {
  FINANCIAL_YEAR: "Financial Year",
  FROM_DATE: "From Date",
  TO_DATE: "To Date",
  DELETE_MESSAGE: 'Are you sure you want to delete this financial year ?'
}

export const Process_mapping = {
  In_out_list: [
    { value: 1, label: 'Input' },
    { value: 2, label: 'Output' }
  ]
}

export const itemcategorycode = {
  rawmaterial: 1,
  finishedgood: 2,
  packingmaterial: 3,
  byproducts: 4,
  semifinished: 5,
  wastage: 6,
  freeitem: 7
}


export const locationcode = {
  packinglocation: 1,
  stores: 3
}

export const customer_status = {
  active: 1,
  inActive: 2
}

export const customer_type = {
  portal : 'Portal',
  mobile : 'Mobile'
}


export const supplier_status = {
  active: 1,
  inActive: 2
}

export const supplier_type = {
  portal : 'Portal',
  mobile : 'Mobile'
}

// BOxing
export const BOXING = {
  ITEMCODE_ERROR: "Please Select Item Code",
  NOOFSET_ERROR: "Please enter No. of Set",
  VALIDQTY_ERROR: "Please enter valid  No. of Set"
}

export const STOCKADJUSTMENT = {
  ITEMCODE_ERROR: "Please select item code",
  ADJUSTTYPE_ERROR: "Please select adjust type",
  ADJUSTQTY_ERROR: "Please enter adjust stock",
  ADJUSTQTYVALID_ERROR: "Please enter valid adjust stock",
  ITEMARRAY_ERROR: "Please add atleast one item",
  ITEMALREADY_ERROR: "Item already exist"
}
// Finished Good
export const FINISHEDGOODS = {
  ITEMCODE_ERROR: "Please Select Item Code"
}
// Current Stock
export const CURRENTSTOCK = {
  ITEMCODE_ERROR: "Please Select Item Code",
  IRONMACHINE_ERROR: "Please Select Iron Machine No."
}

// Current Stock
export const PURCHASERETURN = {
  SUPPLIER_ERROR: "Please select supplier",
  DESIGN_ERROR: "Please select design ID/PO No.",
  MATERIAL_ERROR: "Please select material name",
  COLOR_ERROR: "Please select color",
  INWARDDATE_ERROR: "Please select material date",
  QTY_ERROR: "Please enter no. of qty",
  VALIDQTY_ERROR: "Please enter valid qty",
  EXCESSINWARD_ERROR: "Please enter number of qty less than inward qty"
}
