// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

//  GET Common LIST
export const commonData = createAsyncThunk('JobCard/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        params,
        DesignArray: response1.data.body.designRateArray
      }
    } else {
    }
  }
})

// RateCard LIST
export const RateCardlist = createAsyncThunk('RateCard/RateCardlist', async (params) => {
  const response = await axios.post(`${api.api_url}/production/ratecard/ratecardListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/production/ratecard/ratecardList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        params,
        RateCardList: response1.data.body.RatecardArray,
        RateCardTotalList: response1.data.body.RateCardTotal
      }
    } else {
    }
  }
})
// Master List
export const MasterReportlist = createAsyncThunk('ItemWiseOrder/MasterReportlist', async (params) => {
  const response = await axios.post(`${api.api_url}/orderreport/reportMasterListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/orderreport/reportMasterList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        Design_Card_Lists: response1.data.body.design_code_list
      }
    } else {
    }
  }
})
//saveData Api
export const saveData = createAsyncThunk('RateCard/saveData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/production/ratecard/saveRatecardjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/production/ratecard/saveRatecard`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          message: response1.data.body.message
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        statusFlag: response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('RateCard/deleteData', async params => {
  const response = await axios.post(`${api.api_url}/production/ratecard/deleteRatecardjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/production/ratecard/deleteRatecard`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag: response1.data.body.statusFlag,
        message: response1.data.body.message
      }
    }
  } else {
    return {
      statusFlag: response.data.statusFlag,
      message: response.data.message
    }
  }
})
export const RateCardSlice = createSlice({
  name: 'RateCard',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    RateCardList: [],
    RateCardTotalList: 0,
    statuslist: [],
    statusFlag: 0,
    DesignArray: [],
    statuscode: 0,
    Design_Card_Lists: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
      .addCase(deleteData.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.statusFlag = action.payload.statusFlag
      })
      .addCase(RateCardlist.fulfilled, (state, action) => {
        state.RateCardList = action.payload.RateCardList
        state.RateCardTotalList = action.payload.RateCardTotalList
        state.params = action.payload.params
      })
      .addCase(MasterReportlist.fulfilled, (state, action) => {
        state.Design_Card_Lists = action.payload.Design_Card_Lists
      })
      .addCase(commonData.fulfilled, (state, action) => {
        state.DesignArray = action.payload.DesignArray
        state.params = action.payload.params
      })
  }
})
export const {
  handleStatusFlag
} = RateCardSlice.actions
export default RateCardSlice.reducer
