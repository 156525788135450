// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'
 
  // Dispatch List
  export const Dispatchlist = createAsyncThunk('DispatchListData/dispatchlist', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatched/dispatchListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatched/dispatchList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        dispatch_list: response1.data.body.dispatch_array || [],
        dispatch_total: response1.data.body.dispatch_total || 0,
        overallTotal_sets:  response1.data.body.overallTotal_sets || 0,
        overallTotal_Pieces: response1.data.body.overallTotal_Pieces || 0,
        dispatchWidget: response1.data.body.dispatchWidget || [],
        typeWiseTotalDispatch: response1.data.body.typeWiseTotalDispatch || []
      }
     } else {
      return { 
        dispatch_list: [],
        dispatch_total:  0,
        overallTotal_sets: 0,
        overallTotal_Pieces: 0,
        dispatchWidget: [],
        typeWiseTotalDispatch: []
      }
     } 
  }
  })

  
  // Excel List
  export const ExcelDispatchlist = createAsyncThunk('DispatchListData/exceldispatchlist', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatched/dispatchListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatched/dispatchList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        excel_dispatch_list: response1.data.body.dispatch_array || [],
        excel_result: 'true',
        excel_overallTotal_sets:  response1.data.body.overallTotal_sets || 0,
        excel_overallTotal_Pieces: response1.data.body.overallTotal_Pieces || 0,
        excel_dispatchWidget: response1.data.body.dispatchWidget || []
      }
     } else {
      return { 
        excel_dispatch_list: [],
        excel_result: 'false',
        excel_overallTotal_sets:  0,
        excel_overallTotal_Pieces: 0,
        excel_dispatchWidget: []
      }
     } 
  }
  })
  //Print Dispatch
  export const PrintDispatchlist = createAsyncThunk('DispatchListData/printdispatchlist', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatched/dispatchListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatched/dispatchList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        print_dispatch_list: response1.data.body.dispatch_array || [],
        print_dispatch_company: response1.data.body.Company_Array || [],
        print_result: 'true',
        print_overallTotal_sets:  response1.data.body.overallTotal_sets || 0,
        print_overallTotal_Pieces: response1.data.body.overallTotal_Pieces || 0,
        print_dispatchWidget: response1.data.body.dispatchWidget || []
      }
     } else {
      return { 
        print_dispatch_list: [],
        print_dispatch_company: [],
        print_result: 'false',
        print_overallTotal_sets:  0,
        print_overallTotal_Pieces: 0,
        print_dispatchWidget: []
      }
     } 
  }
  })
   // Master List
   export const dispatchDropdownList = createAsyncThunk('DispatchListData/dispatchDropdownList', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatched/dispatchDropdownListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatched/dispatchDropdownList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        design_dropdown_list: response1.data.body.DesignArray || [],
        design_drop_down_list: response1.data.body.Item_Custom_Array || [],
        customer_dropdown_list: response1.data.body.Customer_Array || [],
        item_category_list: response1.data.body.itemCategory_Array || [],
        agent_list: response1.data.body.Agent_Array || []
      }
     } else {
      return { 
        design_dropdown_list: [],
        design_drop_down_list: [],
        customer_dropdown_list: [],
        item_category_list: [],
        agent_list: []
      }
     } 
  }
  })
  // cancel cancelDispatchData
  export const cancelDispatchData = createAsyncThunk('DispatchListData/cancelDispatchData', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatched/cancelDispatchDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatched/cancelDispatchData`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        statusFlag:response1.data.body.statusFlag, 
        message:response1.data.body.message
      }
     } else {
      return { 
        statusFlag:response1.data.body.statusFlag, 
        message:response1.data.body.message
      }
     } 
  }
  })
  export const PrintItemCustomerWiseDispatchlist = createAsyncThunk('DispatchListData/printItemCustomerWiseDispatchJwt', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatched/printItemCustomerWiseDispatchJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatched/printItemCustomerWiseDispatch`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        print_single_dispatch_list: response1.data.body.dispatch_array || [],
        print_single_dispatch_company: response1.data.body.Company_Array || [],
        print_single_result: 'true'
      }
     } else {
      return { 
        print_single_dispatch_list: [],
        print_single_dispatch_company: [],
        print_single_result: 'false'
      }
     } 
  }
  })
  export const EditDispatchData = createAsyncThunk('DispatchListData/editDispatchData', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatched/editDispatchDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatched/editDispatchData`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        edit_list_data: response1.data.body.dispatch_array         
      }
     } else {
      return { 
        edit_list_data: []
      }
     } 
  }
  })
  // delete DispatchData
  export const deleteDispatchData = createAsyncThunk('DispatchListData/deleteDispatchData', async (params) => {
    try {
    const response = await axios.post(`${api.api_url}/dispatched/deleteDispatchDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatched/deleteDispatchData`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        delete_statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
        delete_message:response1.data.body.message
      }
     } else {
      return { 
        delete_statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
        delete_message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
      }
     } 
  } else {
    return {
      delete_statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      delete_message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      delete_statusFlag:2,
      delete_message:'Server not reachable.Please try again later'
    }    
  }
  })

  export const getBrandList = createAsyncThunk('FinishedGoodList/brandFilterListJwt', async params => {
    try {
    const response = await axios.post(`${api.api_url}/common/brandFilterListJwt`, params)
     if (response && response.data && response.data.body && response.data.body.token) {
     params['jwtToken'] = response.data.body.token
     const response1 = await axios.post(`${api.api_url}/common/brandFilterList`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        all_Brand_List: response1.data.body && response1.data.body.All_Brand_Array ? response1.data.body.All_Brand_Array : [],
        brand_List_Message:response1.data.body.message
      }
     } else {
      return {
        all_Brand_List: response1.data.body ? response1.data.body.All_Brand_Array : [],
        brand_List_Message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
      }
     }  
    } else {
      return {
        all_Brand_List: response.data.body ? response.data.body.All_Brand_Array : [],
        brand_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
     }
    } catch (error) {
      return {
        all_Brand_List: [],
        brand_List_Message:'Server not reachable.Please try again later'
      }
    }
  })
export const DispatchData = createSlice({
  name: 'DispatchListData',
  initialState: { 
    params: {},
    statusFlag:0,
    message:'', 
    dispatch_list:[], 
    excel_dispatch_list:[],
    dispatch_total: 0,
    design_dropdown_list: [],
    design_drop_down_list: [],
    customer_dropdown_list: [],
    excel_result: '',
    print_dispatch_list: [],
    print_dispatch_company: [],
    print_result: '',
    item_category_list: [],
    agent_list: [],
    print_single_dispatch_list: [],
    print_single_dispatch_company: [],
    print_single_result: '',
    overallTotal_sets: 0,
    overallTotal_Pieces: 0,
    dispatchWidget: [],
    typeWiseTotalDispatch: [],
    print_overallTotal_sets: 0,
    print_overallTotal_Pieces: 0,
    print_dispatchWidget: [],
    edit_list_data: [],
    delete_statusFlag: 0,
    delete_message: '',
    all_Brand_List: [],
    brand_List_Message:'',
    excel_overallTotal_sets:  0,
    excel_overallTotal_Pieces: 0,
    excel_dispatchWidget: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    handleDispatchedit: (state, action) => {
      state.editaction = action.payload
    },
    handledeleteFlag: (state, action) => {
      state.delete_statusFlag = action.payload.delete_statusFlag
      state.delete_message = action.payload.delete_message
    },
    clearstoreData: (state, action) => {
      state.excel_dispatch_list = action.payload.excel_dispatch_list  
      state.excel_result = action.payload.excel_result 
      state.print_dispatch_list = action.payload.print_dispatch_list
      state.print_result = action.payload.print_result
      state.print_dispatch_company = action.payload.print_dispatch_company 
      state.print_single_dispatch_list = action.payload.print_single_dispatch_list
      state.print_single_dispatch_company = action.payload.print_single_dispatch_company
      state.print_single_result = action.payload.print_single_result 
      state.print_overallTotal_sets = action.payload.print_overallTotal_sets
      state.print_overallTotal_Pieces = action.payload.print_overallTotal_Pieces
      state.print_dispatchWidget = action.payload.print_dispatchWidget
      state.overallTotal_sets = action.payload.overallTotal_sets
      state.overallTotal_Pieces = action.payload.overallTotal_Pieces
      state.dispatchWidget = action.payload.dispatchWidget
      state.typeWiseTotalDispatch = action.payload.typeWiseTotalDispatch
      state.edit_list_data = action.payload.edit_list_data
      state.excel_overallTotal_sets = action.payload.excel_overallTotal_sets
      state.excel_overallTotal_Pieces = action.payload.excel_overallTotal_Pieces
      state.excel_dispatchWidget = action.payload.excel_dispatchWidget
    }
  },
  extraReducers: builder => {
    builder.addCase(Dispatchlist.fulfilled, (state, action) => {
      state.dispatch_list = action.payload.dispatch_list
      state.dispatch_total = action.payload.dispatch_total
      state.overallTotal_sets = action.payload.overallTotal_sets
      state.overallTotal_Pieces = action.payload.overallTotal_Pieces
      state.dispatchWidget = action.payload.dispatchWidget
      state.typeWiseTotalDispatch = action.payload.typeWiseTotalDispatch
    }) 
    .addCase(dispatchDropdownList.fulfilled, (state, action) => {
      state.design_dropdown_list = action.payload.design_dropdown_list
      state.design_drop_down_list = action.payload.design_drop_down_list
      state.customer_dropdown_list = action.payload.customer_dropdown_list
      state.item_category_list = action.payload.item_category_list
      state.agent_list = action.payload.agent_list      
    })  
    .addCase(ExcelDispatchlist.fulfilled, (state, action) => { 
      state.excel_dispatch_list = action.payload.excel_dispatch_list
      state.excel_result = action.payload.excel_result 
      state.excel_overallTotal_sets = action.payload.excel_overallTotal_sets
      state.excel_overallTotal_Pieces = action.payload.excel_overallTotal_Pieces
      state.excel_dispatchWidget = action.payload.excel_dispatchWidget
    })  
    .addCase(PrintDispatchlist.fulfilled, (state, action) => { 
      state.print_dispatch_list = action.payload.print_dispatch_list
      state.print_dispatch_company = action.payload.print_dispatch_company 
      state.print_result = action.payload.print_result 
      state.print_overallTotal_sets = action.payload.print_overallTotal_sets
      state.print_overallTotal_Pieces = action.payload.print_overallTotal_Pieces
      state.print_dispatchWidget = action.payload.print_dispatchWidget
    })
    .addCase(cancelDispatchData.fulfilled, (state, action) => {
      state.message = action.payload.message
      state.statusFlag = action.payload.statusFlag
    })
    .addCase(PrintItemCustomerWiseDispatchlist.fulfilled, (state, action) => { 
      state.print_single_dispatch_list = action.payload.print_single_dispatch_list
      state.print_single_dispatch_company = action.payload.print_single_dispatch_company
      state.print_single_result = action.payload.print_single_result
    })
    .addCase(EditDispatchData.fulfilled, (state, action) => { 
      state.edit_list_data = action.payload.edit_list_data
    })
    .addCase(deleteDispatchData.fulfilled, (state, action) => {
      state.delete_statusFlag = action.payload.delete_statusFlag
      state.delete_message = action.payload.delete_message
    })
    .addCase(getBrandList.fulfilled, (state, action) => {
      state.brand_List_Message = action.payload.brand_List_Message
      state.all_Brand_List = action.payload.all_Brand_List
    })
  }
})
export const {
  handleStatusFlag, clearstoreData, handleDispatchedit, handledeleteFlag
} = DispatchData.actions
export default DispatchData.reducer
